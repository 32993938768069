import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangeSelectionStrategy } from '@angular/material/datepicker';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { Logger } from 'timeghost-api';
import RangeDateAdapter from './calendar-range-picker/RangeDateAdapter';
import { CalendarRangePickerComponent } from './calendar-range-picker/calendar-range-picker.component';
import { CalendarRangePickerService } from './calendar-range-picker/calendar-range-picker.service';
import { TimeRangeStrategyService } from './customRangeAdapter';
import { TimeRangePickerComponent } from './time-range-picker.component';
function MAT_CALENDAR_RANGE_STRATEGY_PROVIDER_FACTORY(
  parent: MatDateRangeSelectionStrategy<unknown>, // using custom, ignore parent
  adapter: DateAdapter<Date>,
) {
  const l = new Logger('TimeRangePickerModule');
  l.debug({ parent, adapter });
  return new TimeRangeStrategyService(adapter);
}
@NgModule({
  declarations: [TimeRangePickerComponent, CalendarRangePickerComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, DateFnsModule, TranslateModule],
  providers: [
    CalendarRangePickerService,
    { provide: DateAdapter, useClass: RangeDateAdapter },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      deps: [[new Optional(), new SkipSelf(), MAT_DATE_RANGE_SELECTION_STRATEGY], DateAdapter],
      useFactory: MAT_CALENDAR_RANGE_STRATEGY_PROVIDER_FACTORY,
    },
  ],
  exports: [TimeRangePickerComponent],
})
export class TimeRangePickerModule {}
