<mat-card
  class="calendar-range-picker-container !p-0"
  [@slideContent]="animationState"
  (@slideContent.start)="onAnimationStart($event)"
  (@slideContent.done)="onAnimationDone($event)"
>
  <mat-card-content>
    <div class="flex flex-row items-start justify-start">
      <div class="range-container flex-col wflex-[1_1_120px]" *ngIf="this.ranges$Custom | async as ranges" mat-list>
        <ng-container *ngFor="let item of ranges | iterateObject">
          <button
            mat-menu-item
            class="range-item"
            [class.active]="(this.range$ | async)?.name === item.name"
            (click)="this.selectRange(item)"
          >
            {{ item.name | translate }}
          </button>
        </ng-container>
        <button
          mat-menu-item
          class="range-item"
          (click)="selectRange({ rangeType: null })"
          [class.active]="(this.range$ | async)?.name === rangeCustomName"
        >
          {{ 'time-range.preset.custom' | translate }}
        </button>
      </div>
      <div class="calendar-container flex flex-auto flex-col">
        <mat-calendar
          #startCal
          [selected]="calendarValue.value$ | async"
          (selectedChange)="this.selectedChange($event)"
          color="primary"
          class="calender-item"
        ></mat-calendar>
      </div>
    </div>
    <div
      class="calendar-range-footer flex flex-row items-center justify-end gap-[14px]"
      *ngIf="this.range$ | async as r"
    >
      <button mat-raised-button color="primary" (click)="this.saveSelection()" [disabled]="!r.from || !r.to">
        {{ 'utils.apply' | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #defaultDayViewTemplate>
  <span class="time-range-display-start" *ngIf="rangeStart$ | async as dt">{{ dt | formatDate: 'PP' }}</span>
  <span class="time-range-display-divider">-</span>
  <span class="time-range-display-end" *ngIf="rangeEnd$ | async as dt">{{ dt | formatDate: 'PP' }}</span>
</ng-template>

<ng-template #sameDayTemplate>
  <div class="time-range-display flex flex-row items-center justify-center text-center">
    <span class="time-range-display-start">{{ this.rangeStart$ | async | formatDate: 'PP' }}</span>
  </div>
</ng-template>
