import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export default class RangeDateAdapter extends NativeDateAdapter {
  useUtcForDisplay = true;
  getFirstDayOfWeek(): number {
    return 1;
  }
}
